import {getObjectFromLocalStorage, setObjectToLocalStorage} from '@/utils/local-storage.util';

const cameraDevice = {
	state: {
		usingProcesses: {
			camera: [],
		},
		isCameraMicDeviceLoading: false,
		
		devices: {
			audio: {
				selected: {
					input: null
				},
				input: []
			},
			video: {
				selected: {
					input: null
				},
				input: []
			}
		},
		
		videoAvailability: false,
		audioAvailability: false,
		
		cameraVideoTrack: null,
		micAudioTrack: null,
		
		cameraMicInitError: null,
	},
	getters: {
		getCameraMicInitialized(state) {
			return state.usingProcesses.camera.length > 0;
		},
		getCameraMicLoading(state) {
			return state.isCameraMicDeviceLoading;
		},
		getCameraMicInitError(state) {
			return state.cameraMicInitError;
		},
		getInputDevices(state) {
			console.log('dev', state.devices)
			return state.devices;
		},
		getAudioAvailability(state) {
			return state.audioAvailability;
		},
		getVideoAvailability(state) {
			return state.videoAvailability;
		},
		getVideoStreamTrack(state) {
			return state.cameraVideoTrack;
		},
		getAudioStreamTrack(state) {
			return state.micAudioTrack;
		}
	},
	actions: {
		saveSelectedDevicesToLocalStore({state}) {
			setObjectToLocalStorage('deviceSettings', {
				selectedDevices: {
					audio: {
						available: state.audioAvailability,
						input: state.devices.audio.selected.input
					},
					video: {
						available: state.videoAvailability,
						input: state.devices.video.selected.input
					}
				}
			});
		},
		restoreSelectedDevicesFromLocalStore({state}) {
			const data = getObjectFromLocalStorage('deviceSettings');
			if (!data) {
				state.devices.audio.selected.input = state.devices.audio.input[0]?.id ?? null;
				state.devices.video.selected.input = state.devices.video.input[0]?.id ?? null;
				return;
			}
			
			state.devices.audio.selected.input = data.selectedDevices.audio.input;
			state.devices.video.selected.input = data.selectedDevices.video.input;
			
			state.audioAvailability = data.selectedDevices.audio.available;
			state.videoAvailability = data.selectedDevices.video.available;
		},
		async reloadInputDevices({state}) {
			// console.log("[RUN] reloadInputDevices");
			state.devices.audio.input = [];
			state.devices.video.input = [];
			
			const devicesInfo = await navigator.mediaDevices.enumerateDevices();
			for (const deviceInfo of devicesInfo) {
				if (deviceInfo.deviceId.length === 0) continue;
				
				const device = {
					id: deviceInfo.deviceId,
					label: deviceInfo.label
				};
				
				switch (deviceInfo.kind) {
					case 'audioinput':
						state.devices.audio.input.push(device);
						break;
					case 'videoinput':
						state.devices.video.input.push(device);
						break;
				}
			}
		},
		async startCameraMicInputDevice({state, dispatch}, processId) {
			if (processId && !state.usingProcesses.camera.includes(processId))
				state.usingProcesses.camera.push(processId);
			if (!state.videoAvailability && !state.audioAvailability) return;
			
			state.isCameraMicDeviceLoading = true;
			
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: state.videoAvailability ? {
						deviceId: state.devices.video.selected.input
								? {exact: state.devices.video.selected.input}
								: undefined,
						width: { ideal: 999999 },
						height: { ideal: 9999999 },
						frameRate: { ideal: 30, max: 30 }
					} : false,
					audio: state.audioAvailability ? {
						deviceId: state.devices.audio.selected.input
								? {exact: state.devices.audio.selected.input}
								: undefined
					} : false
				});
				
				if (state.cameraVideoTrack) {
					state.cameraVideoTrack.stop();
				}
				
				if (state.micAudioTrack) {
					state.micAudioTrack.stop();
				}
				
				if (stream.getVideoTracks().length) {
					stream
							.getVideoTracks()
							.forEach((track) => (state.cameraVideoTrack = track));
				} else {
					state.cameraVideoTrack = null;
				}
				
				if (stream.getAudioTracks().length) {
					stream
							.getAudioTracks()
							.forEach((track) => (state.micAudioTrack = track));
				} else {
					state.micAudioTrack = null;
				}
			} catch (e) {
				await dispatch('stopCameraMicInputDevice', processId);
				state.videoAvailability = false;
				state.audioAvailability = false;
				
				if (
						e.name === 'NotAllowedError' ||
						e.name === 'PermissionDeniedError'
				) {
					state.cameraMicInitError = 'CAMERA_OR_MIC_PERMISSION_ERROR';
				} else {
					state.cameraMicInitError = 'CAMERA_OR_MIC_UNAVAILABLE_ERROR';
				}
			}
			
			state.isCameraMicDeviceLoading = false;
		},
		async stopCameraMicInputDevice({state}, processId) {
			if (processId) {
				// Remove process ID
				const processIndex = state.usingProcesses.camera.indexOf(processId);
				if (processIndex > -1)
					state.usingProcesses.camera.splice(processIndex, 1);
				if (state.usingProcesses.camera.length > 0) return;
			}
			
			if (state.cameraVideoTrack) {
				state.cameraVideoTrack.stop();
				state.cameraVideoTrack = null;
			}
			
			if (state.micAudioTrack) {
				state.micAudioTrack.stop();
				state.micAudioTrack = null;
			}
			
			state.cameraDeviceInitError = null;
			state.micDeviceInitError = null;
		},
		// Setting device availability
		async setCameraMicInputAvailability(
				{state, dispatch},
				{ data, processId}
		) {
			const { mic, camera } = data;
			state.videoAvailability = camera;
			state.audioAvailability = mic;
			
			if (!camera && !mic) {
				await dispatch('stopCameraMicInputDevice', processId);
			} else {
				await dispatch('startCameraMicInputDevice', processId);
			}

			// Save state
			await dispatch('saveSelectedDevicesToLocalStore');
		},
		// Setting selected input device
		async setMicAudioInputDevice({state, dispatch}, value) {
			// console.log("[CHANGE=DEVICE] setMicAudioInputDevice");
			
			state.devices.audio.selected.input = value;
			await dispatch('saveSelectedDevicesToLocalStore');
			
			await dispatch('stopCameraMicInputDevice', null);
			await dispatch('startCameraMicInputDevice', null);
		},
		async setCameraVideoInputDevice({state, dispatch}, value) {
			// console.log("[CHANGE=DEVICE] setCameraVideoInputDevice");
			state.devices.video.selected.input = value;
			await dispatch('saveSelectedDevicesToLocalStore');
			
			await dispatch('stopCameraMicInputDevice', null);
			await dispatch('startCameraMicInputDevice', null);
		}
	}
};

export default cameraDevice;
