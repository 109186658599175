import {getFromLocalStorage, setToLocalStorage} from '@/utils/local-storage.util';
import {handleGqlResponseBody} from '@/utils/apollo.util';
import {GET_CURRENT_USER_QUERY} from '@/graphql/queries/user/get-current-user.query';

const currentUser = {
	state: {
		user: getFromLocalStorage('user')
				? JSON.parse(getFromLocalStorage('user'))
				: null,
		balance: null
	},
	getters: {
		getCurrentUser(state) {
			console.log('getCurrentUser', state.user)
			return state.user;
		},
		getCurrentUserBalance(state) {
			return state.balance;
		}
	},
	mutations: {
		setCurrentUser(state, user) {
			setToLocalStorage('user', JSON.stringify(user));
			state.user = user;
		},
		setCurrentUserProfilePhotos(state, items) {
			const user = Object.assign(state.user, items.reduce((acc, item) => {
				acc[`profilePhotoX${item.photoSize}`] = item
				return acc;
			}, {}))
			setToLocalStorage('user', JSON.stringify(user));
			state.user = user;
		},
		clearCurrentUser(state) {
			state.user = null;
		}
	},
	actions: {
		async updateCurrentUser({commit}) {
			const result = await handleGqlResponseBody((apollo) => {
				return apollo.mutate({
					mutation: GET_CURRENT_USER_QUERY
				});
			});
			
			commit('setCurrentUser', result);
			return result;
		}
	}
};

export default currentUser;
