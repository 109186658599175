import gql from 'graphql-tag';

export const GET_CURRENT_USER_QUERY = gql`
	query GetCurrentUserQuery {
		getCurrentUser {
			id
			email
			fullName
			profilePhotoX64 {
				photoSize
				fullUri
			}
			profilePhotoX128 {
				photoSize
				fullUri
			}
			profilePhotoX256 {
				photoSize
				fullUri
			}
			profilePhotoX512 {
				photoSize
				fullUri
			}
		}
	}
`;
