import Vue from 'vue';
import VueRouter from 'vue-router';
import PageNotFound from '@/views/PageNotFound';
import AuthComponent from '@/components/AuthComponent';
import {getFromLocalStorage} from '@/utils/local-storage.util';
import AccessDenied from '@/views/AccessDenied';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'main',
		component: () => import('../views/MainView.vue')
	},
	{
		path: '/profile',
		component: () => import('../components/MainComponent.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'profile',
				component: () => import('../views/profile/ProfileView.vue')
			},
			{
				path: 'campaigns',
				component: () => import('../layouts/EmptyLayout.vue'),
				children: [
					{
						path: '',
						name: 'campaigns',
						component: () =>
								import('../views/profile/campaigns/CampaignsView.vue')
					}
				]
			},
			{
				path: 'settings',
				component: () => import('../layouts/EmptyLayout.vue'),
				children: [
					{
						path: '',
						name: 'profile-settings',
						component: () => import('../views/profile/profile-settings/ProfileSettingsView.vue')
					},
					{
						path: 'main',
						name: 'profile-settings-main',
						component: () => import('../views/profile/profile-settings/ProfileSettingsMainView.vue')
					}
				]
			}
		]
	},
	{
		path: '/profile',
		component: () => import('../layouts/EmptyLayout.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: 'campaigns',
				component: () => import('../layouts/EmptyLayout.vue'),
				children: [
					{
						path: ':id(\\d+|new)',
						name: 'campaign-details',
						component: () =>
								import('../views/profile/campaigns/CreateEditView.vue')
					}
				]
			}
		]
	},
	{
		path: '/auth',
		component: AuthComponent,
		children: [
			{
				path: 'logout',
				name: 'logout',
				component: () => import('../views/auth/LogoutView.vue')
			},
			{
				path: 'sign-in',
				name: 'sign-in',
				component: () => import('../views/auth/SignInView.vue')
			},
			{
				path: 'sign-up',
				name: 'sign-up',
				component: () => import('../views/auth/SignUpView.vue')
			},
			{
				path: 'restore-password',
				name: 'restore-password',
				component: () => import('../views/auth/RestorePasswordView.vue')
			},
			{
				path: 'confirm-restore-password',
				name: 'confirm-restore-password',
				component: () => import('../views/auth/ConfirmRestorePasswordView.vue')
			}
		]
	},
	{
		path: '/campaigns',
		component: () => import('../layouts/EmptyLayout.vue'),
		children: [
			{
				path: ':id',
				name: 'campaign',
				component: () => import('../views/campaign/CampaignView.vue')
			}
		]
	},
	{
		path: '/access-denied',
		component: AccessDenied
	},
	{
		path: '*',
		component: PageNotFound
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

let previousRoute = null;
// check redirect role,permission
router.beforeEach(async (to, from, next) => {
	previousRoute = from;
	const isLoggedIn = getFromLocalStorage('accessToken');
	if (to.matched.some((m) => m.meta.auth) && !isLoggedIn) {
		return next({
			name: 'sign-in',
			query: {
				fromPage: to.path
			}
		});
	}
	
	return next();
});

Vue.prototype.$previousRoute = () => previousRoute;
export default router;
