import Vue from "vue";
import VueI18n from "vue-i18n";
import { getObjectFromLocalStorage } from "@/utils/local-storage.util";

Vue.use(VueI18n);

const defaultLocale = getObjectFromLocalStorage("lang") || "en";

export const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    en: require("../i18n/en"),
  },
});
