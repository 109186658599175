import {deleteFromLocalStorage, getFromLocalStorage, setToLocalStorage} from '@/utils/local-storage.util';


export const clearLoginData = () => {
	deleteFromLocalStorage('accessToken');
	deleteFromLocalStorage('refreshToken');
	deleteFromLocalStorage('user');
};

const authentication = {
	state: {
		accessToken: getFromLocalStorage('accessToken'),
		refreshToken: getFromLocalStorage('refreshToken')
	},
	getters: {
		getAccessToken(state) {
			return state.accessToken;
		}
	},
	mutations: {
		setAccessAndRefreshTokens(state, {accessToken, refreshToken}) {
			state.accessToken = setToLocalStorage('accessToken', accessToken);
			state.refreshToken = setToLocalStorage('refreshToken', refreshToken);
		},
		logout() {
			this.commit('clearCurrentUser');
			clearLoginData();
		}
	}
};

export default authentication;
