import Vue from "vue";
import Vuex from "vuex";
import authentication from "@/store/modules/authentication";
import currentUser from "@/store/modules/current-user";
import cameraDevice from "@/store/modules/camera-device";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    currentUser,
    cameraDevice
  },
});
